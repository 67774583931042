<template>
  <div class="store-management-page-box">
    <div class="store-content">
      <!-- 搜索框 -->
      <div class="search-box">
        <div class="input-box">
          <div class="item">
            <span>商家名称</span>
            <el-input v-model="search.cmpName" clearable placeholder="请输入商家名称" />
          </div>
          <div class="item">
            <span>业务类型</span>
            <el-select v-model="search.businessTypeList" clearable collapse-tags multiple placeholder="请选择业务类型">
              <el-option v-for="(item,index) in $store.getters.getDictionaryItem('SHOP_BUSINESS_TYPE')" :key="index" :label="item.dictName" :value="item.dictId" />
            </el-select>
          </div>
          <div class="item">
            <span>经营地区</span>
            <el-cascader
              v-model="search.city"
              clearable
              :props="{ expandTrigger: 'hover', label: 'name', value: 'code', children: 'children', checkStrictly: true }"
              :options="options"
              @change="getSearchParameter"
            />
          </div>
        </div>
        <button class="search-button" @click="getdata">
          搜索
        </button>
      </div>
      <!-- 店铺列表 -->
      <div class="store-list">
        <div v-for="nape in listData" :key="nape.id" class="list clear">
          <div class="left">
            <!-- logo跟名字开始 -->
            <div class="logo-and-name">
              <img v-if="nape.shopLogo" class="logo" :src="$fileUrl + nape.shopLogo" alt="shopLogo">
              <img v-else class="logo" src="@/assets/storeManagement/image/store_logo.png" alt="shopLogo">
              <div class="name">
                <h3 class="elp-1">
                  {{ nape.cmpName }}
                </h3>
                <div class="authentication-status">
                  <!-- 已认证 -->
                  <img v-if="nape.authState==='1'" class="authentication" src="@/assets/storeManagement/icon/authentication.png" alt="icon">
                  <img class="cooperation" src="@/assets/storeManagement/icon/cooperation.png" alt="icon">
                  <span>优选拍档{{ nape.openShopDate }}年</span>
                  <span v-if="nape.factoryId" class="service">加工服务</span>
                </div>
              </div>
            </div>
            <!-- logo跟名字结束 -->
            <!-- 业务类型 -->
            <p class="nape-box elp-1">
              <span>业务类型：</span>
              {{ nape.type }}
            </p>
            <!-- 主营产品 -->
            <p class="nape-box elp-1">
              <span>主营产品：</span>
              {{ nape.mainProduct }}
            </p>
            <!-- 经营地址 -->
            <p class="nape-box elp-1">
              <span>经营地址：</span>
              <i class="elp-1">{{ nape.placeProvinceName }}{{ nape.placeCityName }}{{ nape.placeRegionName }}{{ nape.placeDesc }}</i>
            </p>
            <!-- 进入店铺变扭 -->
            <router-link class="enter-shop-button" :to="{path:'/storeManagement/storeHomepage',query:{shopId:nape.id, id: nape.factoryId}}">
              进入店铺
            </router-link>
          </div>
          <div class="right">
            <!-- 产品列表 -->
            <router-link v-for="(item, index) in nape.respGoodsHallList" :key="index" :to="{path:'/spotHall/spotHallDetail',query: { shopId: nape.id, id: item.id }}" class="product-list">
              <!-- 产品图片 -->
              <img :src="$fileUrl + item.goodsMainImgUrl" alt="产品图片">
              <p class="product-name elp-2">
                {{ item.goodsName }}
                <!-- {{ item.goodsMaterialName }} {{ item.goodsSpecName }} -->
              </p>
              <h4 class="price">
                ￥{{ item.unitPriceIncludingTax||0 }}元/吨
              </h4>
            </router-link>
          </div>
        </div>
        <NoData v-if="listData.length===0" style="padding:32px 0;background: #fff;" :type="5" />
      </div>
      <Pagination
        :get-data-list="getdata"
        :params="search"
        :total="total"
        :layout-option="'sizes,prev, pager, next'"
      />
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination.vue'
import { getSelectAreaTree } from '@/http/common'
import { queryShopListPage } from '@/http/storeManagement'
import NoData from '@/components/NoData.vue'
export default {
  components: { Pagination, NoData },
  data() {
    return {
      options: [],
      total: 0,
      listData: [],
      search: {
        pageSize: 10,
        pageNum: 1,
        cmpName: '',
        businessTypeList: [],
        city: []
      }
    }
  },
  watch: {
    '$route.query.keyword': {
      handler(newVal) {
        this.search.cmpName = newVal
        this.getdata()
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    getSelectAreaTree('00', (res) => {
      this.options = [...res.data]
    })
    if (!this.$route.query.keyword) this.getdata()
  },
  methods: {
    getdata() {
      queryShopListPage(this.search, res => {
        const typeArr = this.$store.getters.getDictionaryItem('SHOP_BUSINESS_TYPE') || []
        res.data.records.forEach(item => {
          item.type = ''
          item.tradeShopBusinessTypeList.forEach((val, index) => {
            const obj = typeArr.find((item) => item.dictId === val.type) || {}
            item.type += (index > 0 ? '、' : '') + obj.dictName
          })
        })
        this.listData = [...res.data.records]
        this.total = res.data.total || 0
      })
    },
    // 当城市级联选择的时候改变参数
    getSearchParameter(city) {
      this.search.placeProvince = city.length > 0 ? city[0] : ''
      this.search.placeCity = city.length > 1 ? city[1] : ''
      this.search.placeRegion = city.length > 2 ? city[2] : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.store-management-page-box {
  width: 100%;
  background-color: #f9f9f9;
  .store-list{
    .list{
        margin-bottom: 16px;
        background-color: #fff;
        padding: 12px 24px;
        .left{
            width: 542px;
            float: left;
            .enter-shop-button{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                width: 99px;
                height: 32px;
                border: 1px solid #e1e1e1;
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                background-color: #fff;
            }
            .enter-shop-button:hover{
                color: #fff;
                border: 0;
                line-height: 32px;
                background-color: #D31E0E;
            }
            .nape-box{
                display: flex;
                align-items: center;
                width: 100%;
                margin-bottom: 8px;
                color: #333333;
                font-size: 14px;
                font-weight: 400;
                i{
                  font-style: normal;
                }
                span{
                    flex: 0 0 78px;
                    color: #666666;
                    font-weight: 400;
                }
            }
            .nape-box:last-of-type{
                margin-bottom: 32px;
            }
            .logo-and-name{
                margin: 12px 0 24px;
                display: flex;
                align-items: center;
                .name{
                    margin-left: 16px;
                    .authentication-status{
                        display: flex;
                        align-items: center;
                        color: #333333;
                        font-size: 12px;
                        font-weight: 400;
                        .authentication{
                            width: 60px;
                            height: 20px;
                            margin-right: 8px;
                        }
                        .cooperation{
                            height: 20px;
                            width: 20px;
                            margin-right: 2px;
                        }
                        .service {
                          background-color: $themeColors;
                          color: #fff;
                          margin-left: 20px;
                          padding: 2px 4px;
                          border-radius: 2px;
                          font-size: 12px;
                        }
                    }
                    h3{
                        margin-bottom: 8px;
                        color: #000000;
                        font-size: 20px;
                        font-weight: 700;
                    }
                }
                .logo{
                    height: 82px;
                    width: 82px;
                }
            }
        }
        .right{
            display: flex;
            float: right;
            width: 564px;
            height: 270px;
            .product-list{
                display: flex;
                align-items: center;
                flex-direction: column;
                flex: 0 0 33.3333%;
                padding: 12px 0;
                cursor: pointer;
                .price{
                    width: calc(100% - 24px);
                    color: #d31e0e;
                    font-size: 16px;
                    font-weight: 500;
                }
                .product-name{
                    width: calc(100% - 24px);
                    margin: 8px 12px;
                    color: #000000;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    line-height: 1.5;
                    height: 44px;
                }
                img{
                    height: 164px;
                    width: 164px;
                }
            }
            .product-list:hover{
                box-shadow: 0 4px 20px 0 rgba(0,0,0,.2);
            }
        }
    }
  }
  .store-content {
    width: $centerPlateWidth;
    margin: auto;
    .search-box {
      margin: 16px 0;
      background-color: #fff;
      padding: 20px;
      .input-box {
        display: inline-block;
        height: 40px;
        .item {
          display: inline-block;
          margin-right: 32px;
          span {
            display: inline-block;
            width: 72px;
            color: #666666;
            font-size: 14px;
            font-weight: 400;
          }
          .el-input,
          .el-cascader,
          .el-select {
            width: 252px;
            height: 40px;
            /deep/.el-input__inner,
            .el-input__inner:focus {
              border: 1px solid #e7e7e7!important;
            }
          }
        }
        .item:last-of-type {
          margin-right: 0;
        }
      }
      .search-button {
        float: right;
        width: 80px;
        height: 40px;
        opacity: 1;
        background: #d31e0e;
        border: 0;
        outline: 0;
        color: #fff;
        font-size: 14px;
      }
    }
  }
}
</style>
